import React, { useState } from 'react';
import MainApp from './MainApp';
import { useNavigate } from 'react-router-dom';
import {Typography,Box,Container,TextField,Button,Snackbar,Alert
} from '@mui/material';

const ConsultarDocumento = () => {
    const [documentNumber, setDocumentNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleConsultar = async () => {
        setLoading(true);
        setError('');
        setSuccess('');
    
        // Validaciones
        if (documentNumber.trim() === '') {
            setError('El número de documento no puede estar vacío.');
            setLoading(false);
            return;
        }
    
        try {
            // Primero realizar la consulta con el número de documento
            const response = await fetch('http://167.99.118.252:5000/consultar-persona', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nroDoc: documentNumber }),
            });
    
            if (response.ok) {
                // Si la consulta es exitosa, realizar la solicitud para descargar el PDF
                const pdfResponse = await fetch('http://167.99.118.252:5000/download-pdf');
                
                if (pdfResponse.ok) {
                    // Convertir la respuesta a blob para manejar la descarga
                    const blob = await pdfResponse.blob();
                    if (blob.size > 0) {
                        const url = window.URL.createObjectURL(blob);
    
                        // Crear un enlace para descargar el archivo
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'persona.pdf'; // Nombre del archivo que se descargará
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
    
                        setSuccess('Consulta y descarga realizada con éxito.');
                        navigate('/bisca');
                    } else {
                        setError('El archivo PDF está vacío.');
                    }
                } else {
                    setError('Error al descargar el archivo PDF.');
                }
            } else {
                const result = await response.json();
                setError(result.error || 'Error al consultar el documento.');
            }
        } catch (err) {
            setError('Error de conexión. Intente nuevamente más tarde.');
        } finally {
            setLoading(false);
        }
    };
    


    return (
        <MainApp>
            <Container
                sx={{
                    backgroundColor: '#fff',
                    minHeight: '43vh',
                    padding: 4,
                    paddingLeft: '90px',
                    paddingRight: '24px',
                }}
            >
                <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Consultar Documento
                    </Typography>
                    <TextField
                        label="Número de Documento"
                        variant="outlined"
                        fullWidth
                        value={documentNumber}
                        onChange={(e) => setDocumentNumber(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConsultar}
                        disabled={loading}
                    >
                        {loading ? 'Consultando...' : 'Consultar'}
                    </Button>
                </Box>
                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
                <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess('')}>
                    <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
                        {success}
                    </Alert>
                </Snackbar>
            </Container>
        </MainApp>
    );
};

export default ConsultarDocumento;
